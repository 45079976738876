body {
  background-color: black;
  margin: 0 auto;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1920px;
  position: relative;
}
main {
  background: var(--blue-gradient);
  min-height: calc(100vh - 72px);
  @include media-breakpoint-down(lg) {
    min-height: calc(100vh - 54px);
  }
}
