.navbar {
  background-color: white;
  padding: 15px 18px;
  z-index: 9999;
  position: fixed;
  top: 0;
  width: calc(100% - 36px);
  max-width: calc(1920px - 36px);
  &+*{
    padding-top: 72px;
    @include media-breakpoint-down(lg) {
      padding-top: 54px;
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 6px 18px 6px;
  }
  &__wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include media-breakpoint-down(lg) {
      height: 42px;
    }
    &--login {
      max-width: 1110px;
    }
  }
  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .dropdown, &>i, .navbar__menu__item, .navbar__profile {
      margin-left: 20px;
    }
    &>.navbar__menu__item {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
  &__profile {
    font-size: 14px;
    color: var(--dark-blue);
    font-weight: 700;
    display: flex;
    align-items: center;
    &>span {
      cursor: pointer;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    img {
      cursor: pointer;
      border: 1px solid var(--green);
      border-radius: 100%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      margin-left: 5px;
    }
  }
  &__button {
    width: 30px;
    height: 26px;
    position: relative;
    cursor: pointer;
    margin-left: 20px;
    @include media-breakpoint-up(lg) {
      display: none;
    }
    &__bar {
      background-color: var(--dark-blue);
      display: block;
      height: 2px;
      margin-bottom: 8px;
      position: relative;
      transition: .3s;
      width: 100%;
      animation-duration: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      &:first-child {
        margin-top: 2px;
      }
    }
    &--open {
      .navbar__button__bar {
        &:nth-child(2) {
          background-color: transparent;
        }
        &:first-child {
          animation-name: cross_top;
        }
        &:last-child {
          animation-name: cross_bottom;
        }
        @keyframes cross_top {
          60% { transform: translateY(10px)}
          100% { transform: translateY(10px) rotate(45deg)}
        }
        @keyframes cross_bottom {
          60% { transform: translateY(-10px)}
          100% { transform: translateY(-10px) rotate(-45deg)}
        }
      }
    }
    &--closed {
      .navbar__button__bar {
        &:first-child {
          animation-name: cross_top_close;
        }
        &:last-child {
          animation-name: cross_bottom_close;
        }
        @keyframes cross_top_close {
          0% { transform: translateY(10px) rotate(45deg)}
          40% { transform: translateY(10px)}
          100% { transform: translateY(0px)}
        }
        @keyframes cross_bottom_close {
          0% { transform: translateY(-10px) rotate(-45deg)}
          40% { transform: translateY(-10px)}
          100% { transform: translateY(0px)}
        }
      }
    }
  }
  &__home {
    width: 133px;
    height: 40px;
    display: flex;
    align-items: flex-end;
    @include media-breakpoint-down(lg) {
      width: 88px;
      height: 26px;
    }
    .navbar__wrapper:not(.navbar__wrapper--login) & {
      @include media-breakpoint-down(lg) {
        width: 27px;
      }
    }
    img {
      width: 92px;
      @include media-breakpoint-down(lg) {
        width: 61px;
      }

      &:first-child {
        height: 100%;
        object-fit: contain;
      }
      &:last-child {
        .navbar__wrapper:not(.navbar__wrapper--login) & {
          @include media-breakpoint-down(lg) {
            display: none;
          }
        }
      }
    }
  }
  &__menu {
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    .navbar__wrapper:not(.navbar__wrapper--login) & {
      @include media-breakpoint-up(lg) {
        display: none;
      }
      &--open {
        @include media-breakpoint-down(lg) {
          display: flex;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      height: 0;
      min-height: 0;
      width: 100%;
      left: 0;
      z-index: 100;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      backdrop-filter: blur(8px);
      top: 54px;
      background-color: rgba(255, 255, 255, 0.95);
      position: absolute;
    }
    &__item {
      color: var(--dark-blue);
      text-decoration: none;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      margin-left: 57px;
      @include media-breakpoint-down(lg) {
        padding: 10px 0;
        margin-left: 20px;
      }
      &--dot {
        position: relative;
        padding-left: 32px;
        @include media-breakpoint-down(lg) {
          padding-left: 0;
        }
        &:before {
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 100%;
          background-color: var(--green);
          position: absolute;
          top: 50%;
          left: 23px;
          transform: translateY(-50%);
          @include media-breakpoint-down(lg) {
            content: none;
          }
        }
      }
      &--bar {
        @include media-breakpoint-down(lg) {
          margin-left: 0 !important;
          padding-left: 20px;
          border-top: 1px solid var(--dark-blue);
        }
      }
      strong {
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    &--open {
      @include media-breakpoint-down(lg) {
        padding-top: 10px;
        min-height: calc(100vh - 54px) !important;
        overflow: scroll;
      }
    }
  }
  &--open {
    @include media-breakpoint-down(lg) {
      position: fixed;
      width: calc(100vw - 36px);
      top: 0;
      left: 0;
    }
    &+*{
      @include media-breakpoint-down(lg) {
        max-height: calc(100vh - 54px);
        min-height: calc(100vh - 54px);
        overflow: hidden;
      }
    }
  }
}

.side-nav {
  background-color: var(--dark-blue);
  position: fixed;
  left: 0;
  top: 121px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 217px);
  padding: 25px 12px 35px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 100;
  @include media-breakpoint-down(md) {
    bottom: 0;
    flex-direction: row;
    top: auto;
    width: calc(100% - 100px);
    height: auto;
    justify-content: space-between;
    padding: 6px 50px 14px;
    position: fixed;
  }
  &:hover {
    .side-nav__texts {
      width: 201px;
      padding-left: 8px;
      padding-right: 15px;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
  &__texts {
    position: absolute;
    height: calc(100% - 60px);
    background-color: #4346B0E5;
    top: 0;
    left: 100%;
    padding: 25px 15px 35px 8px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    padding-left: 0;
    padding-right: 0;
    width: 0;
    @include media-breakpoint-down(md) {
      display: none;
    }
    .side-nav__item {
      line-height: 35px;
    }
  }
  &__item {
    text-decoration: none;
    margin-bottom: 30px;
    color: white;
    white-space: nowrap;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
    &--end {
      margin-top: auto;
      margin-bottom: 0;
    }
  }
}
