@mixin media-breakpoint-down($breakpoint) {
  $breakpoint-value: map-get($breakpoints, $breakpoint);
  @media (max-width: $breakpoint-value) {
    @content;
  }
}

@mixin media-breakpoint-up($breakpoint) {
  $breakpoint-value: map-get($breakpoints, $breakpoint);
  @media (min-width: $breakpoint-value) {
    @content;
  }
}
