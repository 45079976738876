.sort {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 2px;
  position: relative;
  &:before, &:after {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    left: 50%;
  }
  &:before {
    border-top: 1px solid white;
    border-right: 1px solid white;
    transform: translateX(-50%) rotate(-45deg);
    top: 0;
  }
  &:after {
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    transform: translateX(-50%) rotate(-45deg);
    bottom: 0;
  }
  &--top {
    &:before {
      border-bottom: 5px solid var(--green);
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      border-top: 0;
      left: 0;
      transform: none;
      width: 0;
      height: 0;
      top: -1px;
    }
  }
  &--bottom {
    &:after {
      border-top: 5px solid var(--green);
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      border-bottom: 0;
      left: 0;
      transform: none;
      width: 0;
      height: 0;
      bottom: -1px;
    }
  }
}
