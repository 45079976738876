.btn {
  border-radius: 5px;
  padding: 17px 50px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19.5px;
  letter-spacing: 0.01em;
  font-weight: 600;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
  i {
    margin-left: 5px;
  }
  &--white {
    background-color: white;
    color: var(--dark-blue);
  }
  &--ghost {
    border: 1px solid white;
    box-shadow: none;
    color: white;
    &:hover {
      background-color: white;
      color: var(--dark-blue);
    }
    &.btn--blue {
      border-color: var(--dark-blue);
      filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.05));
      color: var(--dark-blue);
      background: transparent
    }
  }
  &--blue {
    color: white;
    background: var(--blue-gradient)
  }
  &--small {
    font-size: 14px;
    line-height: 32px;
    font-weight: 600;
    padding: 2px 15px;
    .inline & {
      padding: 9px 12px;
    }
  }
}
