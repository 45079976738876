body {
  font-family: 'Cabin', sans-serif;
  font-size: 18px;
}

h1, h2, h3, h4, h5 {
  font-family: 'Montserrat', sans-serif;
}

.h1 {
  color: white;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: 0.014em;
  font-weight: 900;
  &--normal {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 10px;
    span {
      font-weight: 400;
    }
    @include media-breakpoint-down(sm) {
      font-size: 26px;
      line-height: 28px;
    }
  }
  &--mobile-space {
    margin-bottom: 14px;
  }
}

h2 {
  font-size: 20px;
  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

.no-mobile {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.inline {
  display: flex;
  align-items: center;
  @include media-breakpoint-down(sm) {
    display: block;
  }
  &>*{
    display: inline;
    @include media-breakpoint-down(sm) {
      white-space: normal;
    }
  }
  .btn {
    margin-right: 10px;
    white-space: nowrap;
    @include media-breakpoint-down(sm) {
      padding: 0;
      border: none;
      line-height: auto;
      text-transform: unset;
      font-family: "Cabin", sans-serif;
      font-size: 18px;
      font-weight: 400;
      margin-right: 0px;
      pointer-events: none;
    }
    i {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.link {
  text-decoration: none;
  color: var(--dark-blue);
  strong {
    text-decoration: underline;
  }
  &--arrow {
    &:before {
      content: "";
      width: 17px;
      height: 11px;
      display: inline-block;
      margin-right: 6px;
      background-image: url('/img/arrow-right-green.svg');
    }
  }
  &--white {
    color: white;
  }
  &--center {
    margin-left: auto;
    margin-right: auto;
  }
}

.text {
  &:not(small) {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }
  &--center {
    text-align: center;
  }
  &--divider {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    &:before, &:after {
      content: "";
      flex: 1;
      border-bottom: 1px solid white;
    }
    &:not(:empty)::before {
      margin-right: 25px;
    }

    &:not(:empty)::after {
      margin-left: 25px;
    }
  }
}

.white {
  color: white;
}

small {
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
}
