.checkbox-label {
  position: relative;
  padding: 4px 0;
  padding-left: 34px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  color: white;
  a {
      color: inherit;
  }
  input[type="checkbox"] {
      position: absolute;
      left: 1px;
      bottom: 0;
      opacity: 0;
      font-size: 1px;
      z-index: -1;
      &:checked ~ .checkmark {
        &::after {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          display: block;
          left: 3px;
          top: 3px;
          background: var(--text-color);
        }
        &--white::after {
          background: white;
        }
        &--dark::after {
          background: orange;
        }
      }
  }
  .checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 26px;
    width: 26px;
    border: 1px solid var(--text-color);
    background-color: white;
      &--white {
          border-color: white;
          background-color: transparent;
      }
      &--dark {
        border-color: orange;
      }
  }
  &--white {
      color: white;
      a {
          color: white;
          text-decoration: underline;
      }
  }
  &--dark {
    color: var(--text-color);
  }
}

.text-input {
  position: relative;
  margin-top: 50px;
  &:first-child {
    margin-top: 0;
  }
  &__format {
    position: absolute;
    right: 0;
    top: 0;
  }
  &__error {
    color: var(--red);
    font-size: 13px;
    position: absolute;
  }
  textarea {
      height: 26px;
      resize: none;
      font-family: "Cabin", sans-serif;
  }
  input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
      width: 100%;
      display: block;
      background-color: transparent;
      color: var(--text-color);
      border: unset;
      border-bottom: 1px solid var(--text-color);
      font-size: 18px;
      line-height: 25px;
      padding: 0;
      font-family: "Cabin", sans-serif;
      font-weight: bold;
      -webkit-appearance: none;
      border-radius: 0;
      &:focus-visible {
          outline: none;
          + label {
              top: -10px;
              font-size: 10px;
              opacity: 1;
          }
      }
      &::placeholder {
          color: transparent;
      }
      &:not(&:placeholder-shown) {
          +label {
              top: -10px;
              font-size: 10px;
              opacity: 1;
          }
      }
  }
  label {
      transition: 0.2s;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 18px;
      font-weight: 800;
      pointer-events: none;
      color: #5C6584;
  }

  @keyframes shake {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
  }
  &--red {
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: 2;
    input[type=text], input[type=email], input[type=tel]{
      border-color: #FF0000;
      color: #FF0000;
    }
    label {
      color: #FF0000;
    }
  }

  &--white {
    label {
      color: #F5F5F5;
    }
    input[type=text], input[type=email], input[type=tel], input[type=password]{
      border-color: white;
      color: white
    }
  }
  &--password {
    &:before {
      content: "";
      width: 21px;
      height: 17px;
      background-image: url('/img/eye-dark.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &.text-input--white {
      &:before {
        background-image: url('/img/eye.svg');
      }
    }
  }
  &--required {
      &::after {
          content: '*';
          color: var(--red);
          position: absolute;
          right: 0px;
          top: 0px;
      }
  }
  &--split {
    display: flex;
    input {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

.password-validation {
  margin-top: 25px;
  &__label {
    line-height: 25px;
  }
  &__checks {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    &__item {
      font-size: 13px;
      line-height: 22px;
      padding-left: 18px;
      position: relative;
      &::before {
        content: "";
        width: 11px;
        height: 1px;
        background-color: white;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &--checked {
        color: var(--green);
        &:before {
          width: 12px;
          height: 9px;
          background-color: transparent;
          background-image: url('/img/check.svg');
        }
      }
    }
  }
  &--white {
    color: white;
  }
}
.dropdown {
  font-size: 14px;
  color: var(--dark-blue);
  position: relative;
  &>* {
    &:first-child {
      cursor: pointer;
    }
  }
  &__list {
    display: none;
    position: absolute;
    left: 0;
    list-style: none;
    padding-left: 0;
    background-color: white;
    padding: 19px 25px 15px 14px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-top: 20px;
    border-radius: 5px;
    width: fit-content;
    &__item {
      padding: 7px 0;
      &>a {
        color: var(--dark-blue);
        text-decoration: none;
      }
    }
    &--open {
      display: initial !important;
      right: 0 !important;
      @include media-breakpoint-down(sm) {
        opacity: 1 !important;
        pointer-events: initial !important;
        z-index: 900 !important;
      }
    }
  }
  &--arrow {
    &>* {
      &:first-child {
        &:after {
          content: "";
          position: relative;
          display: inline-block;
          width: 10px;
          height: 10px;
          border-bottom: 2px solid var(--green);
          border-right: 2px solid var(--green);
          transform: rotate(45deg);
          top: -2px;
          margin-left: 5px;
        }
      }
    }
  }
  &--aside {
    position: initial;
    .dropdown__list {
      position: absolute;
      border-radius: unset;
      display: block;
      padding: 0;
      top: 72px;
      height: calc(100vh - 80px);
      right: -269px;
      left: unset;
      backdrop-filter: blur(5px);
      background: rgba(40, 43, 147, 0.9);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 259px;
      margin: 0;
      z-index: 3;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      color: white;
      transition: all 0.3s ease-in-out;
      @include media-breakpoint-down(lg) {
        top: 54px;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
        z-index: -9999;
        pointer-events: none;
        opacity: 0;
        right: 0;
      }
      &__header {
        padding: 20px 30px 13px 20px;
        background-color: #4346B0;
        font-weight: 700;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__close {
          width: 16px;
          height: 16px;
          position: relative;
          cursor: pointer;
          &::before, &:after {
            content: "";
            height: 2px;
            width: 20px;
            background-color: white;
            position: absolute;
            left: 0;
            top: 50%;
            border-radius: 2px;
          }
          &:before {
            transform: translateY(-50%) rotate(-45deg);
          }
          &:after {
            transform: translateY(-50%) rotate(45deg);
          }
        }
      }
      &__item {
        padding: 24px 0 20px 20px;
        border-top: 1px solid #6565D5;
        display: flex;
        flex-direction: column;
        &__profile {
          display: flex;
          align-items: center;
          &__text {
            display: flex;
            flex-direction: column;
            strong {
              margin-top: 0;
            }
            small {
              color: var(--green);
              font-size: 12px;
            }
          }
          img {
            width: 54px;
            height: 54px;
            margin-right: 8px;
          }
        }
        &>strong {
          margin-top: 10px;
        }
        span {
          display: flex;
          align-items: center;
        }
        a {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          color: white;
          text-decoration: none;
        }
      }
    }
  }
  &--input {
    margin-top: 50px;
    border-bottom: 1px solid var(--text-color);
    padding-bottom: 2px;
    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid var(--text-color);
      border-right: 2px solid var(--text-color);
      transform: rotate(45deg);
      pointer-events: none;
      right: 0;
      top: 0;
    }
    .dropdown {
      position: relative;
      &__list {
        margin-top: 0;
        top: 100%;
        top: calc(100% + 1px);
        width: 100%;
        padding: 5px 0;
        &__item {
          color: var(--text-color);
          font-size: 18px;
          padding: 7px 25px 7px 14px;
          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
          input {
            display: none;
          }
          label {
            display: block;
          }
        }
      }
      &__value {
        font-size: 18px;
        font-weight: 700;
        color: #5C6584;
        display: block;
      }
      &__label {
        position: absolute;
        top: -18px;
        color: var(--text-color);
      }
    }
  }

}
