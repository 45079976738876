.table {
  border-collapse: collapse;
  &__summary {
    font-weight: 800;
  }
  &__flex-cell {
    display: flex;
    justify-content: space-between;
    .project-card__column {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 17px;
      &:last-child {
        flex-grow: 1;
        margin-left: 20px;
      }
      &>strong {
        margin-bottom: 15px;
        display: block;
        span {
          font-weight: 400;
        }
      }
      &__row {
        display: flex;
        justify-content: space-between;
      }
      &--image {
        padding: 0;
        height: unset;
        width: 138px;
      }
    }
  }
  &__header {
    th {
      position: relative;
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: white;
        position: absolute;
        bottom: -7px;
        left: 0;
      }
    }
  }
  &--big {
    min-width: 800px;
  }
  &--simple {
    @include media-breakpoint-down(sm) {
      display: none;
    }
    .table {
      &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: "";
          display: inline-block;
          width: 11px;
          height: 17px;
          margin-right: 4px;
          background-image: url('/img/arrow-green.svg');
        }
        &--down {
          &:before {
            background-image: url('/img/arrow-red.svg');
          }
        }
      }
      &__status {
        background-color: var(--dark-blue);
        color: white;
        padding: 5px;
        min-width: 63px;
        display: inline-block;
        margin: -5px 0;
        &--paid {
          background-color: var(--green);
        }
        &--failed {
          background-color: var(--red);
        }
      }
    }
    width: 100%;
    color: var(--text-color);
    td, th {
      text-align: center;
      font-size: 13px;
      &:first-child {
        text-align: left;
      }
    }
    td {
      padding: 10px 0;
      font-weight: 600;
      line-height: 18px;
      &:first-child {
        padding-left: 13px;
      }
    }
    th {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      letter-spacing: -0.02em;
      line-height: 26px;
      padding-bottom: 12px;
    }
    tr {
      &:first-child {
        position: relative;
        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: var(--text-color);
          position: absolute;
          left: 0;
          bottom: 5px;
        }
      }
      &:nth-child(2n) {
        background-color: #E5F0FF
      }
    }
  }
  &--border {
    font-family: 'Montserrat', sans-serif;
    color: var(--text-color);
    width: 100%;
    th {
      font-size: 13px;
      padding-bottom: 15px;
    }
    th, td {
      text-align: left;
      font-weight: 700;
      border-bottom: 1px solid var(--text-color);
    }
    td {
      font-size: 14px;
      padding: 22px 0;
    }
    tr {
      &:nth-child(2n + 1) {
        background-color: #E5F0FF;
      }
      &:first-child {
        background: transparent;
      }
    }
  }
  &--mobile {
    display: table;
    width: 100%;
    @include media-breakpoint-up(sm) {
      display: none;
    }
    .table {
      &__date {
        display: flex;
        flex-direction: column;
        small {
          font-size: 10px;
        }
      }
      &__status {
        min-width: 52px;
        font-size: 11px;
        padding: 5px 0;
      }
    }
    &.table--border {
      list-style: none;
      padding-left: 0;
      margin: 0;
      li {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        &:nth-of-type(2n) {
          background-color: #E5F0FF;
        }
        &>* {
          padding: 10px 0 8px;
          border-bottom: 1px solid var(--text-color);
        }
      }
    }
  }
}

.two-tables {
  color: var(--text-color);
  display: flex;
  margin-top: 30px;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
  h2 {
    @extend .list__title;
  }
  &__table {
    flex-grow: 1;
    &:first-child {
      margin-right: 60px;
      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
}
