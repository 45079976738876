.status-bar {
  width: calc(100% - 40px);
  background-color: rgba(40, 44, 147, 0.8);
  padding: 12px 20px 4px;
  &__bar {
    width: calc(100% - 10px);
    height: 12px;
    background-color: white;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: var(--progress);
      background-color: var(--green);
      border-radius: 10px;
    }
    &--dark {
      background-color: var(--dark-blue);
    }
  }
  &__info {
    color: white;
    display: flex;
    justify-content: space-between;
    span, strong {
      font-size: 16px;
      line-height: 32px;
      @include media-breakpoint-down(lg) {
        font-size: 14px;
      }
    }
  }
  &--small {
    padding: 0;
    background-color: transparent;
    width: 100%;
    .status-bar {
      &__bar {
        background-color: var(--dark-blue);
        height: 6px;
        width: 100%;
        border-radius: 6px;
        &:after {
          border-radius: 6px;
        }
      }
    }
  }
  &--transparent {
    background-color: transparent;
    padding: 0;
    width: 100%;
    margin-top: 11px;
    margin-bottom: 30px;
    .status-bar {
      &__info {
       color: var(--dark-blue);
      }
      &__bar {
        width: 100%;
      }
    }
  }
}
