.tabs {
  &__controls {
    display: flex;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
      overflow-x: scroll;
      width: calc(100% + 18px);
    }
    &__item {
      font-size: 16px;
      padding-bottom: 9px;
      line-height: 19px;
      letter-spacing: 0.02em;
      margin-right: 30px;
      color: rgba(255, 255, 255, 0.5);
      position: relative;
      cursor: pointer;
      @include media-breakpoint-down(sm) {
        margin-right: 48px;
        white-space: nowrap;
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease-in-out;
        background-color: rgba(255, 255, 255, 0.5);
      }
      &--active {
        color: white;
        &:after {
          background-color: white;
          width: 100%;
        }
      }
    }
  }
}
