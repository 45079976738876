.wrapper {
  display: flex;
  &--center {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
  &--narrow {
    max-width: 485px;
    @include media-breakpoint-down(md) {
      min-height: calc(100vh - 70px);
    }
    &>small {
      &:last-child {
        margin-top: 80px;
        padding-bottom: 50px;
        @include media-breakpoint-down(md) {
          margin-top: 40px;
        }
      }
    }
  }
  &--white {
    color: white;
  }
  &--vertical {
    flex-direction: column;
  }
  &--content {
    padding: 0 18px;
    width: calc(100% - 36px) !important;
  }
  &--main {
    padding: 51px 28px 90px 98px;
    flex-direction: column;
    @include media-breakpoint-down(md) {
      padding: 34px 18px 80px;
    }
    &>h2 {
      margin-top: 30px;
      margin-bottom: 25px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }
  }
  &--mobile-full {
    @include media-breakpoint-down(sm) {
      padding: 34px 0px 80px;
    }
    .tabs__controls {
      @include media-breakpoint-down(sm) {
        width: calc(100% - 36px);
        padding: 0 18px;
        background-color: white;
      }
      &__item {
        @include media-breakpoint-down(sm) {
          color: var(--text-color);
          margin-bottom: 0;
        }
        &:after {
          @include media-breakpoint-down(sm) {
            background-color: rgba(52, 63, 101, 0.5);
          }
        }
      }
    }
    .header {
      @include media-breakpoint-down(sm) {
        padding: 0 18px;
        margin-bottom: 30px;
      }
    }
    .card, .two-columns__column {
      @include media-breakpoint-down(sm) {
        border-radius: 0;
        margin-bottom: 0
      }
    }
    &>.two-columns {
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
      .two-columns__column {
        margin-bottom: 0;
      }
    }

  }
  &--form {
    flex-direction: column;
    max-width: 354px;
    @include media-breakpoint-down(md) {
      flex-grow: 1;
    }
    .btn, .link, .text--divider {
      margin-top: 30px;
    }
    .btn {
      &:last-child {
        margin-bottom: 30px;
      }
    }
    h1 {
      margin-bottom: 40px;
      margin-top: 82px;
      white-space: nowrap;
      @include media-breakpoint-down(lg) {
        margin-top: 70px;
      }
      @include media-breakpoint-down(md) {
        white-space: normal;
      }
      &+.text-input {
        margin-top: 0;
      }
    }
    &.wrapper--center {
      width: 100%;
      max-width: 354px;
    }
  }
  &--table {
    overflow-x: scroll;
  }
}
