.list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 60px;
  &__title {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-weight: 800;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  li {
    border-bottom: 1px solid var(--text-color);
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 32px;
    margin-top: 7px;
    span {
      flex-shrink: 0;
    }
    strong {
      margin-left: 60px;
      text-align: end;
      a {
        color: var(--text-color);
      }
    }
    img {
      max-height: 32px;
      object-fit: contain;
      width: auto;
    }
  }
}
