.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(39, 39, 63, 0.8);
  &__close {
    width: 28px;
    height: 22px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    &:before, &:after {
      content: "";
      width: 30px;
      height: 2px;
      background-color: var(--dark-blue);
      position: absolute;
      left: 0;
      top: 50%;
    }
    &:before {
      transform: translateY(-50%) rotate(-45deg);
    }
    &:after {
      transform: translateY(-50%) rotate(45deg);
    }
  }
  &__container {
    margin: 20vh auto 0 auto;
    background: white;
    max-width: calc(100% - 80px);
    position: relative;
    padding: 30px;
    border-top: 5px solid var(--dark-blue);
    border-radius: 5px;
    color: var(--text-color);
    .btn {
      margin-top: 30px;
      width: fit-content;
      @include media-breakpoint-down(sm) {
        width: auto;
      }
    }
    h2 {
      font-weight: 900;
      font-size: 34px;
      letter-spacing: 0.014em;
      margin-top: 0;
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        font-size: 26px;
      }
    }
  }
  &--open {
    display: block;
  }
  &--narrow {
    .modal__container {
      max-width: 400px;
    }
  }
}
