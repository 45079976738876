:root {
  --blue-gradient: linear-gradient(90deg, #4047D5 0%, #3136AE 53.51%, #272A92 100%);
  --text-color: #343F65;
  --dark-blue: #282B93;
  --green: #21C98C;
  --red: #FF5A5A;
  --white: #fff;
}

.btn {
  border-radius: 5px;
  padding: 17px 50px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19.5px;
  letter-spacing: 0.01em;
  font-weight: 600;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.btn i {
  margin-left: 5px;
}
.btn--white {
  background-color: white;
  color: var(--dark-blue);
}
.btn--ghost {
  border: 1px solid white;
 background-color:transparent;
 box-shadow: none;
  color: white;
}
.btn--ghost:hover {
  background-color: white;
  color: var(--dark-blue);
}
.btn--ghost.btn--blue {
  border-color: var(--dark-blue);
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.05));
  color: var(--dark-blue);
  background: transparent;
}
.btn--blue {
  color: white;
  background: var(--blue-gradient);
}
.btn--small {
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
  padding: 2px 15px;
}
.inline .btn--small {
  padding: 9px 12px;
}

.card {
  background-color: white;
  border-radius: 5px;
  box-shadow: 3px 10px 15px rgba(0, 0, 0, 0.07);
  padding: 30px;
  color: var(--dark-blue);
  position: relative;
}
@media (max-width: 768px) {
  .card {
    padding: 30px 18px;
  }
}
.card__text {
  color: var(--text-color);
}
.card__text--narrow {
  max-width: 1110px;
}
.card__text--two-col {
  column-count: 2;
  column-gap: 30px;
}
@media (max-width: 576px) {
  .card__text--two-col {
    column-count: 1;
    font-size: 14px;
  }
}
.card__text--two-col p {
  margin-top: 0;
  break-inside: avoid-column;
  line-height: 32px;
}
@media (max-width: 576px) {
  .card__text--two-col p {
    line-height: 24px;
  }
}
.card__text--two-col a {
  color: var(--text-color);
}
.card__header {
  display: flex;
  justify-content: space-between;
  color: var(--text-color);
  padding: 40px 30px 30px;
  border-bottom: 1px solid var(--text-color);
  margin-bottom: 17px;
}
@media (max-width: 576px) {
  .card__header {
    padding: 10px 18px 16px;
  }
}
.card__header__item__label {
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.08em;
}
@media (max-width: 576px) {
  .card__header__item__label {
    font-size: 13px;
  }
}
.card__header__item h5 {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 30px;
}
@media (max-width: 576px) {
  .card__header__item h5 {
    font-size: 22px;
    margin-top: 1px;
  }
}
.card__header__item h5 small {
  font-size: 16px;
}
@media (max-width: 576px) {
  .card__header__item h5 small {
    font-size: 13px;
  }
}
.card__content {
  padding: 0 30px 26px;
}
@media (max-width: 576px) {
  .card__content {
    padding: 0 18px 26px;
  }
}
.card__content__price {
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  margin-top: 23px;
}
@media (max-width: 576px) {
  .card__content__price {
    margin-top: 10px;
  }
}
.card__content__price__label {
  font-size: 15px;
  text-transform: uppercase;
  line-height: 25px;
  font-weight: 700;
}
@media (max-width: 576px) {
  .card__content__price__label {
    font-size: 13px;
    margin-bottom: -5px;
  }
}
.card__content__price__amount {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
}
.card__content__price__amount small {
  font-size: 16px;
  font-weight: 700;
}
.card__content__price--green {
  color: var(--green);
}
@media (min-width: 576px) {
  .card__button {
    display: none;
  }
}
.card__column {
  width: calc(50% - 140px);
  display: flex;
  flex-direction: column;
}
@media (max-width: 992px) {
  .card__column {
    width: 100%;
  }
}
.card__column__text__icon {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.card__column__text__icon i {
  margin-right: 10px;
}
.card__column__text__icon p:last-child {
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .card__column__text--shortened {
    margin-bottom: 40px;
    cursor: pointer;
  }
  .card__column__text--shortened > *:not(:first-child) {
    display: none;
  }
}
@media (max-width: 576px) {
  .card__column__text--shortened:after {
    content: "Read more...";
    display: block;
  }
}
.card__column__text + .text-input {
  margin-top: 20px !important;
}
.card__column p {
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 24px;
  font-size: 16px;
}
.card__column .text-input {
  margin-top: 63px;
}
.card__column__form__row {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card__column__form__row .btn {
  font-size: 12px;
  padding: 2px !important;
  width: 158px !important;
  flex-grow: 0;
}
.card__column__form__row > * {
  flex-grow: 1;
  margin-top: 0 !important;
  max-width: calc(50% - 15px);
}
.card__column__form__row--btn .text-input {
  flex-basis: 342px;
  max-width: unset;
}
.card__column__form__row--btn .btn {
  flex-basis: 158px;
  margin-left: 30px;
}
@media (max-width: 576px) {
  .card__column__form__row--btn .btn {
    display: none;
  }
}
.card__column__form .btn {
  margin-top: 30px;
}
.card__column__form .checkbox-label {
  margin-top: 50px;
  display: inline-block;
}
.card__column:first-child:after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: var(--dark-blue);
  position: absolute;
  top: 0;
  left: 50%;
}
@media (max-width: 992px) {
  .card__column:first-child:after {
    content: none;
  }
}
.card__column--narrow {
  width: calc(50% - 210px);
  margin-right: 70px;
}
@media (max-width: 992px) {
  .card__column--narrow {
    width: 100%;
  }
}
.card__column--wide {
  width: calc(50% - 50px);
}
@media (max-width: 992px) {
  .card__column--wide {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .card__column--wide:not(:first-child) {
    display: none;
  }
}
.card__column--wide .btn {
  margin-top: 30px;
  width: 206px;
  padding: 17px 0;
  max-width: 100%;
}
@media (max-width: 576px) {
  .card__column--wide .btn {
    width: 100%;
  }
}
.card__column--wide .card__column__form {
  max-width: 503px;
}
.card__item {
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .card__item:nth-last-child(1), .card__item:nth-last-child(2) {
    margin-bottom: 0;
  }
}
@media (max-width: 768px) {
  .card__item {
    width: 50%;
    margin-bottom: 20px;
  }
}
.card__item h5 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 20px;
}
@media (max-width: 768px) {
  .card__item h5 {
    margin-bottom: 0;
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .card__item h5 span {
    font-size: 12px;
  }
}
.card__item > span {
  color: var(--text-color);
}
@media (max-width: 768px) {
  .card__item > span {
    font-size: 16px;
  }
}
.card__title {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.01em;
  line-height: 22px;
  white-space: nowrap;
  color: var(--text-color);
}
@media (max-width: 768px) {
  .card__title {
    white-space: normal;
  }
}
.card__list {
  display: flex;
  justify-content: space-around;
  list-style: none;
  counter-reset: numbers;
  flex-basis: 710px;
}
@media (max-width: 768px) {
  .card__list {
    flex-basis: unset;
    flex-direction: column;
    padding-left: 0;
    position: relative;
    margin-top: 40px;
  }
}
.card__list::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--dark-blue);
  top: 71px;
  position: absolute;
  left: 0;
}
@media (max-width: 768px) {
  .card__list::before {
    width: 1px;
    height: calc(100% - 20px);
    top: 10px;
    left: 17px;
  }
}
.card__list__item {
  counter-increment: numbers;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 50px;
  font-size: 13px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
@media (max-width: 768px) {
  .card__list__item {
    padding-left: 42px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.card__list__item:before {
  content: counter(numbers);
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background-color: white;
  border: 1px solid var(--dark-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  top: 5px;
  left: 0;
}
.card__list__item:after {
  content: "";
  width: 30px;
  height: 30px;
  border: 1px solid var(--dark-blue);
  position: absolute;
  border-radius: 100%;
  z-index: 2;
  top: 7px;
  left: 2px;
}
.card__list__item--complete:before {
  color: white;
  background-color: var(--green);
  border-color: transparent;
}
.card__list__item--complete:after {
  border-color: white;
}
.card__list__item--active:before {
  color: white;
  background-color: var(--dark-blue);
  border-color: transparent;
}
.card__list__item--active:after {
  border-color: white;
}
.card--horizontal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .card--horizontal {
    margin-bottom: 0;
  }
}
.card--horizontal .card__title {
  margin-bottom: 34px;
  color: var(--dark-blue);
}
@media (max-width: 768px) {
  .card--horizontal .card__title {
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .card--horizontal {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .card--list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.card--two-columns {
  display: flex;
  justify-content: space-between;
  color: var(--text-color);
}
@media (max-width: 992px) {
  .card--two-columns {
    flex-direction: column;
  }
}
.card--border-top {
  border-top: 5px solid var(--dark-blue);
}
.card--no-pad {
  padding: 0;
}

.cards--grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 37px;
  padding-top: 30px;
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .cards--grid {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 0;
  }
}
.cards--table {
  margin-top: 30px;
  border-collapse: separate !important;
  border-spacing: 0 13px;
  width: 100%;
}
.cards--table th {
  color: white;
  text-align: left;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: -0.02em;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.cards--table th:nth-child(2) {
  text-align: center;
}
.cards--table .project-card {
  background-color: transparent;
  border-bottom: 13px solid transparent;
}
.cards--table .project-card__column {
  padding-top: 37px;
}
.cards--table .project-card__column__button {
  padding-top: 33px;
}
.cards--table .project-card__column__button .btn {
  font-size: 12px;
  line-height: unset;
  padding: 5px 1px;
  margin-right: 30px;
}
.cards--table .project-card__column .status-bar {
  margin-top: 12px;
}
.cards--table .project-card__column--image {
  height: 92px;
  width: 151px;
  display: flex;
  padding-top: 0px;
}
.cards--table .project-card__column--image__text {
  padding-top: 37px;
}
.cards--table .project-card__column--image__text a {
  white-space: nowrap;
  color: var(--text-color);
  font-weight: 700;
  margin-bottom: 4px;
  display: inline-block;
}
.cards--table .project-card__column--image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-right: 22px;
}
.cards--table .project-card__column--align-end {
  text-align: right;
  width: 150px;
}
@media (max-width: 1200px) {
  .cards--table .project-card__column--align-end {
    width: 100px;
  }
}
.cards--table .project-card td {
  background-color: white;
  overflow: hidden;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.02em;
  vertical-align: top;
  padding: 0;
}
.cards--table .project-card td > img {
  width: 151px;
  height: 92px;
  object-fit: cover;
}
.cards--table .project-card td:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.cards--table .project-card td:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.cards--table--white {
  width: 100%;
}
.cards--table--white .table__header th {
  color: var(--text-color);
}
.cards--table--white .table__header th:after {
  background-color: var(--text-color);
}
.cards--table--white .project-card {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}
.cards--table--white .project-card__column--align-end {
  width: 75px;
}

.project-card {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
}
.project-card__image {
  padding-bottom: 48%;
  background-size: cover;
  position: relative;
}
@media (max-width: 992px) {
  .project-card__image {
    padding-bottom: 70%;
  }
}
.project-card__image__time-left {
  position: absolute;
  border-radius: 20px;
  text-transform: uppercase;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  font-size: 13px;
  letter-spacing: 0.01em;
  font-weight: 700;
  color: white;
  top: 18px;
  right: 14px;
  padding: 5px 9px;
  z-index: 1;
}
.project-card__image__time-left:before, .project-card__image__time-left:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}
.project-card__image__time-left:before {
  width: 100%;
  height: 100%;
  background-color: var(--green);
  z-index: -1;
}
.project-card__image__time-left:after {
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  background-color: #A5A5B0;
  z-index: -2;
}
.project-card__image__status-bar {
  position: absolute;
  bottom: 0;
  left: 0;
}
.project-card__text {
  padding: 15px 20px 30px;
  color: var(--text-color);
}
.project-card__text__title {
  font-size: 20px;
  margin-top: 0;
  letter-spacing: 0.02em;
  line-height: 34px;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  .project-card__text__title {
    margin-bottom: 5px;
  }
}
.project-card__text__about {
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
}
.project-card__text__list {
  list-style: none;
  padding-left: 0;
  column-count: 2;
  column-gap: 250px;
}
@media (max-width: 1400px) {
  .project-card__text__list {
    column-gap: 150px;
  }
}
@media (max-width: 1200px) {
  .project-card__text__list {
    column-gap: 50px;
  }
}
@media (max-width: 992px) {
  .project-card__text__list {
    column-count: 1;
  }
}
.project-card__text__list li {
  font-size: 16px;
  line-height: 29px;
  display: flex;
  justify-content: space-between;
}
.project-card__text__list li strong {
  font-family: "Montserrat", sans-serif;
}
.project-card__text__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.project-card__text__links .link {
  font-size: 16px;
  color: var(--dark-blue);
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #6565D5;
  color: white;
  z-index: 10;
  align-items: center;
  height: 46px;
}
@media (max-width: 992px) {
  .header {
    position: relative;
    top: 0;
  }
}
@media (max-width: 576px) {
  .header {
    border-bottom: none;
    height: auto;
  }
}
.header .h1 {
  transition: all 0.3s ease-in-out;
}
.header__filter {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
@media (max-width: 992px) {
  .header__filter {
    display: none;
  }
}
.header__filter__item {
  margin-left: 20px;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
}
.header__filter__item span {
  line-height: 24px;
  font-size: 12px;
}
.header__filter__item i {
  margin-right: 10px;
}
.header__filter__item--active span {
  position: relative;
}
.header__filter__item--active span:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
}
.header--sticky {
  position: sticky;
  top: 72px;
}
@media (max-width: 992px) {
  .header--sticky {
    position: relative;
    top: 0;
  }
}
@media (min-width: 992px) {
  .header--top {
    background: linear-gradient(90deg, rgba(64, 71, 213, 0.9) 0%, rgba(49, 54, 174, 0.9) 53.51%, rgba(39, 42, 146, 0.9) 100%);
    backdrop-filter: blur(2px);
    border-bottom: none;
  }
}
@media (min-width: 992px) {
  .header--top .h1 {
    font-size: 16px;
    padding-bottom: 0;
  }
}

.subheader {
  margin-top: 41px;
  margin-bottom: 35px;
}

.list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 60px;
}
.list__title, .text-and-images__title, .two-tables h2, .card__column h2 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-weight: 800;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  .list__title, .text-and-images__title, .two-tables h2, .card__column h2 {
    font-size: 18px;
    line-height: 22px;
  }
}
.list li {
  border-bottom: 1px solid var(--text-color);
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 32px;
  margin-top: 7px;
}
.list li span {
  flex-shrink: 0;
}
.list li strong {
  margin-left: 60px;
  text-align: end;
}
.list li strong a {
  color: var(--text-color);
}
.list li img {
  max-height: 32px;
  object-fit: contain;
  width: auto;
}

.checkbox-label {
  position: relative;
  padding: 4px 0;
  padding-left: 34px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  color: white;
}
.checkbox-label a {
  color: inherit;
}
.checkbox-label input[type=checkbox] {
  position: absolute;
  left: 1px;
  bottom: 0;
  opacity: 0;
  font-size: 1px;
  z-index: -1;
}
.checkbox-label input[type=checkbox]:checked ~ .checkmark::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  display: block;
  left: 3px;
  top: 3px;
  background: var(--text-color);
}
.checkbox-label input[type=checkbox]:checked ~ .checkmark--white::after {
  background: white;
}
.checkbox-label input[type=checkbox]:checked ~ .checkmark--dark::after {
  background: orange;
}
.checkbox-label .checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 26px;
  width: 26px;
  border: 1px solid var(--text-color);
  background-color: white;
}
.checkbox-label .checkmark--white {
  border-color: white;
  background-color: transparent;
}
.checkbox-label .checkmark--dark {
  border-color: orange;
}
.checkbox-label--white {
  color: white;
}
.checkbox-label--white a {
  color: white;
  text-decoration: underline;
}
.checkbox-label--dark {
  color: var(--text-color);
}

.text-input {
  position: relative;
  margin-top: 50px;
}
.text-input:first-child {
  margin-top: 0;
}
.text-input__format {
  position: absolute;
  right: 0;
  top: 0;
}
.text-input__error {
  color: var(--red);
  font-size: 13px;
  position: absolute;
}
.text-input textarea {
  height: 26px;
  resize: none;
  font-family: "Cabin", sans-serif;
}
.text-input input[type=text], .text-input input[type=email], .text-input input[type=tel], .text-input input[type=password], .text-input textarea {
  width: 100%;
  display: block;
  background-color: transparent;
  color: var(--text-color);
  border: unset;
  border-bottom: 1px solid var(--text-color);
  font-size: 18px;
  line-height: 25px;
  padding: 0;
  font-family: "Cabin", sans-serif;
  font-weight: bold;
  -webkit-appearance: none;
  border-radius: 0;
}
.text-input input[type=text]:focus-visible, .text-input input[type=email]:focus-visible, .text-input input[type=tel]:focus-visible, .text-input input[type=password]:focus-visible, .text-input textarea:focus-visible {
  outline: none;
}
.text-input input[type=text]:focus-visible + label, .text-input input[type=email]:focus-visible + label, .text-input input[type=tel]:focus-visible + label, .text-input input[type=password]:focus-visible + label, .text-input textarea:focus-visible + label {
  top: -10px;
  font-size: 10px;
  opacity: 1;
}
.text-input input[type=text]::placeholder, .text-input input[type=email]::placeholder, .text-input input[type=tel]::placeholder, .text-input input[type=password]::placeholder, .text-input textarea::placeholder {
  color: transparent;
}
.text-input input[type=text]:not(.text-input input[type=text]:placeholder-shown, .text-input input[type=email]:placeholder-shown, .text-input input[type=tel]:placeholder-shown, .text-input input[type=password]:placeholder-shown, .text-input textarea:placeholder-shown) + label, .text-input input[type=email]:not(.text-input input[type=text]:placeholder-shown, .text-input input[type=email]:placeholder-shown, .text-input input[type=tel]:placeholder-shown, .text-input input[type=password]:placeholder-shown, .text-input textarea:placeholder-shown) + label, .text-input input[type=tel]:not(.text-input input[type=text]:placeholder-shown, .text-input input[type=email]:placeholder-shown, .text-input input[type=tel]:placeholder-shown, .text-input input[type=password]:placeholder-shown, .text-input textarea:placeholder-shown) + label, .text-input input[type=password]:not(.text-input input[type=text]:placeholder-shown, .text-input input[type=email]:placeholder-shown, .text-input input[type=tel]:placeholder-shown, .text-input input[type=password]:placeholder-shown, .text-input textarea:placeholder-shown) + label, .text-input textarea:not(.text-input input[type=text]:placeholder-shown, .text-input input[type=email]:placeholder-shown, .text-input input[type=tel]:placeholder-shown, .text-input input[type=password]:placeholder-shown, .text-input textarea:placeholder-shown) + label {
  top: -10px;
  font-size: 10px;
  opacity: 1;
}
.text-input label {
  transition: 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  font-weight: 800;
  pointer-events: none;
  color: #5C6584;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
.text-input--red {
  animation-name: shake;
  animation-duration: 0.3s;
  animation-iteration-count: 2;
}
.text-input--red input[type=text], .text-input--red input[type=email], .text-input--red input[type=tel] {
  border-color: #FF0000;
  color: #FF0000;
}
.text-input--red label {
  color: #FF0000;
}
.text-input--white label {
  color: #F5F5F5;
}
.text-input--white input[type=text], .text-input--white input[type=email], .text-input--white input[type=tel], .text-input--white input[type=password] {
  border-color: white;
  color: white;
}
.text-input--password:before {
  content: "";
  width: 21px;
  height: 17px;
  background-image: url("/img/eye-dark.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.text-input--password.text-input--white:before {
  background-image: url("/img/eye.svg");
}
.text-input--required::after {
  content: "*";
  color: var(--red);
  position: absolute;
  right: 0px;
  top: 0px;
}
.text-input--split {
  display: flex;
}
.text-input--split input:first-child {
  margin-right: 10px;
}

.password-validation {
  margin-top: 25px;
}
.password-validation__label {
  line-height: 25px;
}
.password-validation__checks {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}
.password-validation__checks__item {
  font-size: 13px;
  line-height: 22px;
  padding-left: 18px;
  position: relative;
}
.password-validation__checks__item::before {
  content: "";
  width: 11px;
  height: 1px;
  background-color: white;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.password-validation__checks__item--checked {
  color: var(--green);
}
.password-validation__checks__item--checked:before {
  width: 12px;
  height: 9px;
  background-color: transparent;
  background-image: url("/img/check.svg");
}
.password-validation--white {
  color: white;
}

.dropdown {
  font-size: 14px;
  color: var(--dark-blue);
  position: relative;
}
.dropdown > *:first-child {
  cursor: pointer;
}
.dropdown__list {
  display: none;
  position: absolute;
  left: 0;
  list-style: none;
  padding-left: 0;
  background-color: white;
  padding: 19px 25px 15px 14px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 20px;
  border-radius: 5px;
  width: fit-content;
}
.dropdown__list__item {
  padding: 7px 0;
}
.dropdown__list__item > a {
  color: var(--dark-blue);
  text-decoration: none;
}
.dropdown__list--open {
  display: initial !important;
  right: 0 !important;
}
@media (max-width: 576px) {
  .dropdown__list--open {
    opacity: 1 !important;
    pointer-events: initial !important;
    z-index: 900 !important;
  }
}
.dropdown--arrow > *:first-child:after {
  content: "";
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid var(--green);
  border-right: 2px solid var(--green);
  transform: rotate(45deg);
  top: -2px;
  margin-left: 5px;
}
.dropdown--aside {
  position: initial;
}
.dropdown--aside .dropdown__list {
  position: absolute;
  border-radius: unset;
  display: block;
  padding: 0;
  top: 72px;
  height: calc(100vh - 80px);
  right: -269px;
  left: unset;
  backdrop-filter: blur(5px);
  background: rgba(40, 43, 147, 0.9);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 259px;
  margin: 0;
  z-index: 3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 992px) {
  .dropdown--aside .dropdown__list {
    top: 54px;
  }
}
@media (max-width: 576px) {
  .dropdown--aside .dropdown__list {
    width: 100%;
    z-index: -9999;
    pointer-events: none;
    opacity: 0;
    right: 0;
  }
}
.dropdown--aside .dropdown__list__header {
  padding: 20px 30px 13px 20px;
  background-color: #4346B0;
  font-weight: 700;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown--aside .dropdown__list__header__close {
  width: 16px;
  height: 16px;
  position: relative;
  cursor: pointer;
}
.dropdown--aside .dropdown__list__header__close::before, .dropdown--aside .dropdown__list__header__close:after {
  content: "";
  height: 2px;
  width: 20px;
  background-color: white;
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 2px;
}
.dropdown--aside .dropdown__list__header__close:before {
  transform: translateY(-50%) rotate(-45deg);
}
.dropdown--aside .dropdown__list__header__close:after {
  transform: translateY(-50%) rotate(45deg);
}
.dropdown--aside .dropdown__list__item {
  padding: 24px 0 20px 20px;
  border-top: 1px solid #6565D5;
  display: flex;
  flex-direction: column;
}
.dropdown--aside .dropdown__list__item__profile {
  display: flex;
  align-items: center;
}
.dropdown--aside .dropdown__list__item__profile__text {
  display: flex;
  flex-direction: column;
}
.dropdown--aside .dropdown__list__item__profile__text strong {
  margin-top: 0;
}
.dropdown--aside .dropdown__list__item__profile__text small {
  color: var(--green);
  font-size: 12px;
}
.dropdown--aside .dropdown__list__item__profile img {
  width: 54px;
  height: 54px;
  margin-right: 8px;
}
.dropdown--aside .dropdown__list__item > strong {
  margin-top: 10px;
}
.dropdown--aside .dropdown__list__item span {
  display: flex;
  align-items: center;
}
.dropdown--aside .dropdown__list__item a {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}
.dropdown--input {
  margin-top: 50px;
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 2px;
}
.dropdown--input:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid var(--text-color);
  border-right: 2px solid var(--text-color);
  transform: rotate(45deg);
  pointer-events: none;
  right: 0;
  top: 0;
}
.dropdown--input .dropdown {
  position: relative;
}
.dropdown--input .dropdown__list {
  margin-top: 0;
  top: 100%;
  top: calc(100% + 1px);
  width: 100%;
  padding: 5px 0;
}
.dropdown--input .dropdown__list__item {
  color: var(--text-color);
  font-size: 18px;
  padding: 7px 25px 7px 14px;
}
.dropdown--input .dropdown__list__item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.dropdown--input .dropdown__list__item input {
  display: none;
}
.dropdown--input .dropdown__list__item label {
  display: block;
}
.dropdown--input .dropdown__value {
  font-size: 18px;
  font-weight: 700;
  color: #5C6584;
  display: block;
}
.dropdown--input .dropdown__label {
  position: absolute;
  top: -18px;
  color: var(--text-color);
}

.status-bar {
  width: calc(100% - 40px);
  background-color: rgba(40, 44, 147, 0.8);
  padding: 12px 20px 4px;
}
.status-bar__bar {
  width: calc(100% - 10px);
  height: 12px;
  background-color: white;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.status-bar__bar:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: var(--progress);
  background-color: var(--green);
  border-radius: 10px;
}
.status-bar__bar--dark {
  background-color: var(--dark-blue);
}
.status-bar__info {
  color: white;
  display: flex;
  justify-content: space-between;
}
.status-bar__info span, .status-bar__info strong {
  font-size: 16px;
  line-height: 32px;
}
@media (max-width: 992px) {
  .status-bar__info span, .status-bar__info strong {
    font-size: 14px;
  }
}
.status-bar--small {
  padding: 0;
  background-color: transparent;
  width: 100%;
}
.status-bar--small .status-bar__bar {
  background-color: var(--dark-blue);
  height: 6px;
  width: 100%;
  border-radius: 6px;
}
.status-bar--small .status-bar__bar:after {
  border-radius: 6px;
}
.status-bar--transparent {
  background-color: transparent;
  padding: 0;
  width: 100%;
  margin-top: 11px;
  margin-bottom: 30px;
}
.status-bar--transparent .status-bar__info {
  color: var(--dark-blue);
}
.status-bar--transparent .status-bar__bar {
  width: 100%;
}

i {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
}
i.google-logo {
  background-image: url("/img/google.svg");
  width: 62px;
  height: 20px;
  margin-bottom: -2px;
}
i.bank-id-logo {
  background-image: url("/img/logo-bank-id.svg");
  width: 71px;
  height: 15px;
}
i.home-icon {
  background-image: url("/img/home.svg");
  width: 34px;
  height: 30px;
}
i.cubes-icon {
  background-image: url("/img/cubes.svg");
  width: 34px;
  height: 30px;
}
i.layers-icon {
  background-image: url("/img/layers.svg");
  width: 34px;
  height: 30px;
}
i.support-icon {
  background-image: url("/img/support.svg");
  width: 34px;
  height: 30px;
}
i.bell-icon {
  background-image: url("/img/bell.svg");
  width: 22px;
  height: 25px;
}
i.bell-icon--notification {
  position: relative;
}
i.bell-icon--notification:after {
  content: "";
  width: 4px;
  height: 4px;
  background-color: var(--green);
  border-radius: 100%;
  position: absolute;
  top: 1px;
  right: -4px;
}
i.plus {
  position: relative;
  width: 12px;
  height: 12px;
  padding-right: 5px;
}
i.plus:before, i.plus:after {
  content: "";
  height: 1px;
  width: 12px;
  background-color: var(--dark-blue);
  position: absolute;
  left: 0;
  top: 50%;
}
i.plus::after {
  transform: rotate(90deg) translateY(-50%);
}
i.plus:before {
  transform: translateY(-50%);
}
i.question-blue {
  background-image: url("/img/help.svg");
  width: 15px;
  height: 15px;
  margin-left: 3px;
}
i.card-icon {
  background-image: url("/img/card.svg");
  width: 32px;
  height: 25px;
  opacity: 0.7;
  margin-right: 10px;
}
i.person-icon {
  background-image: url("/img/person.svg");
  width: 25px;
  height: 25px;
  margin-right: 10px;
  opacity: 0.7;
}
i.bank-icon {
  background-image: url("/img/bank.svg");
  width: 25px;
  height: 25px;
  margin-right: 10px;
  opacity: 0.7;
}
i.lock-icon {
  background-image: url("/img/lock.svg");
  width: 25px;
  height: 25px;
  margin-right: 10px;
  opacity: 0.7;
}
i.fingerprint-icon {
  background-image: url("/img/finger-print.svg");
  width: 25px;
  height: 26px;
  margin-right: 10px;
  opacity: 0.7;
}
i.grid-icon {
  background-image: url("/img/grid.svg");
  width: 25px;
  height: 25px;
}
i.table-icon {
  background-image: url("/img/table.svg");
  width: 29px;
  height: 26px;
}
i.check-shield {
  background-image: url("/img/check-shield.svg");
  width: 32px;
  height: 33px;
  flex-shrink: 0;
}
i.download-icon {
  background-image: url("/img/download-icon.svg");
  width: 14px;
  height: 16px;
}

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(39, 39, 63, 0.8);
}
.modal__close {
  width: 28px;
  height: 22px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.modal__close:before, .modal__close:after {
  content: "";
  width: 30px;
  height: 2px;
  background-color: var(--dark-blue);
  position: absolute;
  left: 0;
  top: 50%;
}
.modal__close:before {
  transform: translateY(-50%) rotate(-45deg);
}
.modal__close:after {
  transform: translateY(-50%) rotate(45deg);
}
.modal__container {
  margin: 20vh auto 0 auto;
  background: white;
  max-width: calc(100% - 80px);
  position: relative;
  padding: 30px;
  border-top: 5px solid var(--dark-blue);
  border-radius: 5px;
  color: var(--text-color);
}
.modal__container .btn {
  margin-top: 30px;
  width: fit-content;
}
@media (max-width: 576px) {
  .modal__container .btn {
    width: auto;
  }
}
.modal__container h2 {
  font-weight: 900;
  font-size: 34px;
  letter-spacing: 0.014em;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .modal__container h2 {
    font-size: 26px;
  }
}
.modal--open {
  display: block;
}
.modal--narrow .modal__container {
  max-width: 400px;
}

.sort {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 2px;
  position: relative;
}
.sort:before, .sort:after {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  left: 50%;
}
.sort:before {
  border-top: 1px solid white;
  border-right: 1px solid white;
  transform: translateX(-50%) rotate(-45deg);
  top: 0;
}
.sort:after {
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  transform: translateX(-50%) rotate(-45deg);
  bottom: 0;
}
.sort--top:before {
  border-bottom: 5px solid var(--green);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 0;
  left: 0;
  transform: none;
  width: 0;
  height: 0;
  top: -1px;
}
.sort--bottom:after {
  border-top: 5px solid var(--green);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 0;
  left: 0;
  transform: none;
  width: 0;
  height: 0;
  bottom: -1px;
}

.table {
  border-collapse: collapse;
}
.table__summary {
  font-weight: 800;
}
.table__flex-cell {
  display: flex;
  justify-content: space-between;
}
.table__flex-cell .project-card__column {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 17px;
}
.table__flex-cell .project-card__column:last-child {
  flex-grow: 1;
  margin-left: 20px;
}
.table__flex-cell .project-card__column > strong {
  margin-bottom: 15px;
  display: block;
}
.table__flex-cell .project-card__column > strong span {
  font-weight: 400;
}
.table__flex-cell .project-card__column__row {
  display: flex;
  justify-content: space-between;
}
.table__flex-cell .project-card__column--image {
  padding: 0;
  height: unset;
  width: 138px;
}
.table__header th {
  position: relative;
}
.table__header th:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: white;
  position: absolute;
  bottom: -7px;
  left: 0;
}
.table--big {
  min-width: 800px;
}
.table--simple {
  width: 100%;
  color: var(--text-color);
}
@media (max-width: 576px) {
  .table--simple {
    display: none;
  }
}
.table--simple .table__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table--simple .table__arrow:before {
  content: "";
  display: inline-block;
  width: 11px;
  height: 17px;
  margin-right: 4px;
  background-image: url("/img/arrow-green.svg");
}
.table--simple .table__arrow--down:before {
  background-image: url("/img/arrow-red.svg");
}
.table--simple .table__status {
  background-color: var(--dark-blue);
  color: white;
  padding: 5px;
  min-width: 63px;
  display: inline-block;
  margin: -5px 0;
}
.table--simple .table__status--paid {
  background-color: var(--green);
}
.table--simple .table__status--failed {
  background-color: var(--red);
}
.table--simple td, .table--simple th {
  text-align: center;
  font-size: 13px;
}
.table--simple td:first-child, .table--simple th:first-child {
  text-align: left;
}
.table--simple td {
  padding: 10px 0;
  font-weight: 600;
  line-height: 18px;
}
.table--simple td:first-child {
  padding-left: 13px;
}
.table--simple th {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 26px;
  padding-bottom: 12px;
}
.table--simple tr:first-child {
  position: relative;
}
.table--simple tr:first-child::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--text-color);
  position: absolute;
  left: 0;
  bottom: 5px;
}
.table--simple tr:nth-child(2n) {
  background-color: #E5F0FF;
}
.table--border {
  font-family: "Montserrat", sans-serif;
  color: var(--text-color);
  width: 100%;
}
.table--border th {
  font-size: 13px;
  padding-bottom: 15px;
}
.table--border th, .table--border td {
  text-align: left;
  font-weight: 700;
  border-bottom: 1px solid var(--text-color);
}
.table--border td {
  font-size: 14px;
  padding: 22px 0;
}
.table--border tr:nth-child(2n+1) {
  background-color: #E5F0FF;
}
.table--border tr:first-child {
  background: transparent;
}
.table--mobile {
  display: table;
  width: 100%;
}
@media (min-width: 576px) {
  .table--mobile {
    display: none;
  }
}
.table--mobile .table__date {
  display: flex;
  flex-direction: column;
}
.table--mobile .table__date small {
  font-size: 10px;
}
.table--mobile .table__status {
  min-width: 52px;
  font-size: 11px;
  padding: 5px 0;
}
.table--mobile.table--border {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.table--mobile.table--border li {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.table--mobile.table--border li:nth-of-type(2n) {
  background-color: #E5F0FF;
}
.table--mobile.table--border li > * {
  padding: 10px 0 8px;
  border-bottom: 1px solid var(--text-color);
}

.two-tables {
  color: var(--text-color);
  display: flex;
  margin-top: 30px;
}
@media (max-width: 576px) {
  .two-tables {
    flex-direction: column;
  }
}
.two-tables__table {
  flex-grow: 1;
}
.two-tables__table:first-child {
  margin-right: 60px;
}
@media (max-width: 576px) {
  .two-tables__table:first-child {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.tabs__controls {
  display: flex;
  text-transform: uppercase;
}
@media (max-width: 576px) {
  .tabs__controls {
    overflow-x: scroll;
    width: calc(100% + 18px);
  }
}
.tabs__controls__item {
  font-size: 16px;
  padding-bottom: 9px;
  line-height: 19px;
  letter-spacing: 0.02em;
  margin-right: 30px;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  cursor: pointer;
}
@media (max-width: 576px) {
  .tabs__controls__item {
    margin-right: 48px;
    white-space: nowrap;
  }
}
.tabs__controls__item:hover:after {
  width: 100%;
}
.tabs__controls__item:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5);
}
.tabs__controls__item--active {
  color: white;
}
.tabs__controls__item--active:after {
  background-color: white;
  width: 100%;
}

.text-and-images {
  color: var(--text-color);
  margin-top: 60px;
  margin-bottom: 60px;
  max-width: 912px;
}
.text-and-images__images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
@media (max-width: 768px) {
  .text-and-images__images {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
}
.text-and-images__images img {
  width: 100%;
}

.two-columns {
  display: flex;
  justify-content: space-between;
  color: var(--text-color);
}
@media (max-width: 1200px) {
  .two-columns {
    flex-direction: column;
    margin-bottom: 30px;
  }
}
.two-columns__column {
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 576px) {
  .two-columns__column {
    order: 1;
  }
}
.two-columns__column__image--cover {
  height: 100%;
  object-fit: cover;
}
.two-columns__column:first-child {
  width: calc(46% - 33px);
}
@media (max-width: 1200px) {
  .two-columns__column:first-child {
    width: 100%;
    margin-bottom: 30px;
  }
}
.two-columns__column:last-child {
  width: calc(54% - 33px);
}
@media (max-width: 1200px) {
  .two-columns__column:last-child {
    width: 100%;
    margin-bottom: 30px;
  }
}
.two-columns__column img {
  width: 100%;
}
.two-columns__column > .btn {
  width: fit-content;
}
@media (max-width: 576px) {
  .two-columns__column > .btn {
    width: 100%;
  }
}
.two-columns__column--overflow {
  overflow: visible;
}
@media (max-width: 576px) {
  .two-columns__column--mobile-first {
    order: 0;
    overflow: visible;
  }
}
@media (max-width: 576px) {
  .two-columns__column--image {
    height: 180px;
  }
}
.two-columns--shadow .two-columns__column {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

body {
  font-family: "Cabin", sans-serif;
  font-size: 18px;
}

h1, h2, h3, h4, h5 {
  font-family: "Montserrat", sans-serif;
}

.h1 {
  color: white;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: 0.014em;
  font-weight: 900;
}
.h1--normal {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.h1--normal span {
  font-weight: 400;
}
@media (max-width: 576px) {
  .h1--normal {
    font-size: 26px;
    line-height: 28px;
  }
}
.h1--mobile-space {
  margin-bottom: 14px;
}

h2 {
  font-size: 20px;
}
@media (max-width: 576px) {
  h2 {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .no-mobile {
    display: none;
  }
}

.inline {
  display: flex;
  align-items: center;
}
@media (max-width: 576px) {
  .inline {
    display: block;
  }
}
.inline > * {
  display: inline;
}
@media (max-width: 576px) {
  .inline > * {
    white-space: normal;
  }
}
.inline .btn {
  margin-right: 10px;
  white-space: nowrap;
}
@media (max-width: 576px) {
  .inline .btn {
    padding: 0;
    border: none;
    line-height: auto;
    text-transform: unset;
    font-family: "Cabin", sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin-right: 0px;
    pointer-events: none;
  }
}
@media (max-width: 576px) {
  .inline .btn i {
    display: none;
  }
}

.link {
  text-decoration: none;
  color: var(--dark-blue);
}
.link strong {
  text-decoration: underline;
}
.link--arrow:before {
  content: "";
  width: 17px;
  height: 11px;
  display: inline-block;
  margin-right: 6px;
  background-image: url("/img/arrow-right-green.svg");
}
.link--white {
  color: white;
}
.link--center {
  margin-left: auto;
  margin-right: auto;
}

.text:not(small) {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}
.text--center {
  text-align: center;
}
.text--divider {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
}
.text--divider:before, .text--divider:after {
  content: "";
  flex: 1;
  border-bottom: 1px solid white;
}
.text--divider:not(:empty)::before {
  margin-right: 25px;
}
.text--divider:not(:empty)::after {
  margin-left: 25px;
}

.white {
  color: white;
}

small {
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
}

.wrapper {
  display: flex;
}
.wrapper--center {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
.wrapper--narrow {
  max-width: 485px;
}
@media (max-width: 768px) {
  .wrapper--narrow {
    min-height: calc(100vh - 70px);
  }
}
.wrapper--narrow > small:last-child {
  margin-top: 80px;
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .wrapper--narrow > small:last-child {
    margin-top: 40px;
  }
}
.wrapper--white {
  color: white;
}
.wrapper--vertical {
  flex-direction: column;
}
.wrapper--content {
  padding: 0 18px;
  width: calc(100% - 36px) !important;
}
.wrapper--main {
  padding: 51px 28px 90px 98px;
  flex-direction: column;
}
@media (max-width: 768px) {
  .wrapper--main {
    padding: 34px 18px 80px;
  }
}
.wrapper--main > h2 {
  margin-top: 30px;
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .wrapper--main > h2 {
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .wrapper--mobile-full {
    padding: 34px 0px 80px;
  }
}
@media (max-width: 576px) {
  .wrapper--mobile-full .tabs__controls {
    width: calc(100% - 36px);
    padding: 0 18px;
    background-color: white;
  }
}
@media (max-width: 576px) {
  .wrapper--mobile-full .tabs__controls__item {
    color: var(--text-color);
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  .wrapper--mobile-full .tabs__controls__item:after {
    background-color: rgba(52, 63, 101, 0.5);
  }
}
@media (max-width: 576px) {
  .wrapper--mobile-full .header {
    padding: 0 18px;
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .wrapper--mobile-full .card, .wrapper--mobile-full .two-columns__column {
    border-radius: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  .wrapper--mobile-full > .two-columns {
    margin-bottom: 0;
  }
}
.wrapper--mobile-full > .two-columns .two-columns__column {
  margin-bottom: 0;
}
.wrapper--form {
  flex-direction: column;
  max-width: 354px;
}
@media (max-width: 768px) {
  .wrapper--form {
    flex-grow: 1;
  }
}
.wrapper--form .btn, .wrapper--form .link, .wrapper--form .text--divider {
  margin-top: 30px;
}
.wrapper--form .btn:last-child {
  margin-bottom: 30px;
}
.wrapper--form h1 {
  margin-bottom: 40px;
  margin-top: 82px;
  white-space: nowrap;
}
@media (max-width: 992px) {
  .wrapper--form h1 {
    margin-top: 70px;
  }
}
@media (max-width: 768px) {
  .wrapper--form h1 {
    white-space: normal;
  }
}
.wrapper--form h1 + .text-input {
  margin-top: 0;
}
.wrapper--form.wrapper--center {
  width: 100%;
  max-width: 354px;
}
.wrapper--table {
  overflow-x: scroll;
}

body {
  background-color: black;
  margin: 0 auto;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1920px;
  position: relative;
}

main {
  background: var(--blue-gradient);
  min-height: calc(100vh - 72px);
}
@media (max-width: 992px) {
  main {
    min-height: calc(100vh - 54px);
  }
}

.navbar {
  background-color: white;
  padding: 15px 18px;
  z-index: 9999;
  position: fixed;
  top: 0;
  width: calc(100% - 36px);
  max-width: 1884px;
}
.navbar + * {
  padding-top: 72px;
}
@media (max-width: 992px) {
  .navbar + * {
    padding-top: 54px;
  }
}
@media (max-width: 992px) {
  .navbar {
    padding: 6px 18px 6px;
  }
}
.navbar__wrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 992px) {
  .navbar__wrapper {
    height: 42px;
  }
}
.navbar__wrapper--login {
  max-width: 1110px;
}
.navbar__info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.navbar__info .dropdown, .navbar__info > i, .navbar__info .navbar__menu__item, .navbar__info .navbar__profile {
  margin-left: 20px;
}
@media (max-width: 992px) {
  .navbar__info > .navbar__menu__item {
    display: none;
  }
}
.navbar__profile {
  font-size: 14px;
  color: var(--dark-blue);
  font-weight: 700;
  display: flex;
  align-items: center;
}
.navbar__profile > span {
  cursor: pointer;
}
@media (max-width: 992px) {
  .navbar__profile > span {
    display: none;
  }
}
.navbar__profile img {
  cursor: pointer;
  border: 1px solid var(--green);
  border-radius: 100%;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin-left: 5px;
}
.navbar__button {
  width: 30px;
  height: 26px;
  position: relative;
  cursor: pointer;
  margin-left: 20px;
}
@media (min-width: 992px) {
  .navbar__button {
    display: none;
  }
}
.navbar__button__bar {
  background-color: var(--dark-blue);
  display: block;
  height: 2px;
  margin-bottom: 8px;
  position: relative;
  transition: 0.3s;
  width: 100%;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.navbar__button__bar:first-child {
  margin-top: 2px;
}
.navbar__button--open .navbar__button__bar:nth-child(2) {
  background-color: transparent;
}
.navbar__button--open .navbar__button__bar:first-child {
  animation-name: cross_top;
}
.navbar__button--open .navbar__button__bar:last-child {
  animation-name: cross_bottom;
}
@keyframes cross_top {
  60% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(10px) rotate(45deg);
  }
}
@keyframes cross_bottom {
  60% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(-10px) rotate(-45deg);
  }
}
.navbar__button--closed .navbar__button__bar:first-child {
  animation-name: cross_top_close;
}
.navbar__button--closed .navbar__button__bar:last-child {
  animation-name: cross_bottom_close;
}
@keyframes cross_top_close {
  0% {
    transform: translateY(10px) rotate(45deg);
  }
  40% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes cross_bottom_close {
  0% {
    transform: translateY(-10px) rotate(-45deg);
  }
  40% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.navbar__home {
  width: 133px;
  height: 40px;
  display: flex;
  align-items: flex-end;
}
@media (max-width: 992px) {
  .navbar__home {
    width: 88px;
    height: 26px;
  }
}
@media (max-width: 992px) {
  .navbar__wrapper:not(.navbar__wrapper--login) .navbar__home {
    width: 27px;
  }
}
.navbar__home img {
  width: 92px;
}
@media (max-width: 992px) {
  .navbar__home img {
    width: 61px;
  }
}
.navbar__home img:first-child {
  height: 100%;
  object-fit: contain;
}
@media (max-width: 992px) {
  .navbar__wrapper:not(.navbar__wrapper--login) .navbar__home img:last-child {
    display: none;
  }
}
.navbar__menu {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
@media (min-width: 992px) {
  .navbar__wrapper:not(.navbar__wrapper--login) .navbar__menu {
    display: none;
  }
}
@media (max-width: 992px) {
  .navbar__wrapper:not(.navbar__wrapper--login) .navbar__menu--open {
    display: flex;
  }
}
@media (max-width: 992px) {
  .navbar__menu {
    height: 0;
    min-height: 0;
    width: 100%;
    left: 0;
    z-index: 100;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(8px);
    top: 54px;
    background-color: rgba(255, 255, 255, 0.95);
    position: absolute;
  }
}
.navbar__menu__item {
  color: var(--dark-blue);
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-left: 57px;
}
@media (max-width: 992px) {
  .navbar__menu__item {
    padding: 10px 0;
    margin-left: 20px;
  }
}
.navbar__menu__item--dot {
  position: relative;
  padding-left: 32px;
}
@media (max-width: 992px) {
  .navbar__menu__item--dot {
    padding-left: 0;
  }
}
.navbar__menu__item--dot:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: var(--green);
  position: absolute;
  top: 50%;
  left: 23px;
  transform: translateY(-50%);
}
@media (max-width: 992px) {
  .navbar__menu__item--dot:before {
    content: none;
  }
}
@media (max-width: 992px) {
  .navbar__menu__item--bar {
    margin-left: 0 !important;
    padding-left: 20px;
    border-top: 1px solid var(--dark-blue);
  }
}
.navbar__menu__item strong {
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .navbar__menu--open {
    padding-top: 10px;
    min-height: calc(100vh - 54px) !important;
    overflow: scroll;
  }
}
@media (max-width: 992px) {
  .navbar--open {
    position: fixed;
    width: calc(100vw - 36px);
    top: 0;
    left: 0;
  }
}
@media (max-width: 992px) {
  .navbar--open + * {
    max-height: calc(100vh - 54px);
    min-height: calc(100vh - 54px);
    overflow: hidden;
  }
}

.side-nav {
  background-color: var(--dark-blue);
  position: fixed;
  left: 0;
  top: 121px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 217px);
  padding: 25px 12px 35px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 100;
}
@media (max-width: 768px) {
  .side-nav {
    bottom: 0;
    flex-direction: row;
    top: auto;
    width: calc(100% - 100px);
    height: auto;
    justify-content: space-between;
    padding: 6px 50px 14px;
    position: fixed;
  }
}
.side-nav:hover .side-nav__texts {
  width: 201px;
  padding-left: 8px;
  padding-right: 15px;
}
@media (max-width: 768px) {
  .side-nav:hover .side-nav__texts {
    display: none;
  }
}
.side-nav__texts {
  position: absolute;
  height: calc(100% - 60px);
  background-color: rgba(67, 70, 176, 0.8980392157);
  top: 0;
  left: 100%;
  padding: 25px 15px 35px 8px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  padding-left: 0;
  padding-right: 0;
  width: 0;
}
@media (max-width: 768px) {
  .side-nav__texts {
    display: none;
  }
}
.side-nav__texts .side-nav__item {
  line-height: 35px;
}
.side-nav__item {
  text-decoration: none;
  margin-bottom: 30px;
  color: white;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .side-nav__item {
    margin-bottom: 0;
  }
}
.side-nav__item--end {
  margin-top: auto;
  margin-bottom: 0;
}