.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #6565D5;
  color: white;
  z-index: 10;
  align-items: center;
  height: 46px;
  @include media-breakpoint-down(lg) {
    position: relative;
    top: 0;
  }
  @include media-breakpoint-down(sm) {
    border-bottom: none;
    height: auto;
  }
  .h1 {
    transition: all 0.3s ease-in-out;
  }
  &__filter {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    @include media-breakpoint-down(lg) {
      display: none;
    }
    &__item {
      margin-left: 20px;
      display: flex;
      align-items: center;
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 12px;
      span {
        line-height: 24px;
        font-size: 12px;
      }
      i {
        margin-right: 10px;
      }
      &--active {
        span {
          position: relative;
          &:after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: white;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
  &--sticky {
    position: sticky;
    top: 72px;
    @include media-breakpoint-down(lg) {
      position: relative;
      top: 0;
    }
  }
  &--top {
    @include media-breakpoint-up(lg) {
      background: linear-gradient(90deg, rgba(64, 71, 213, 0.9) 0%, rgba(49, 54, 174, 0.9) 53.51%, rgba(39, 42, 146, 0.9) 100%);
      backdrop-filter: blur(2px);
      border-bottom: none;
    }
    .h1 {
      @include media-breakpoint-up(lg) {
        font-size: 16px;
        padding-bottom: 0;
      }
    }
  }
}
.subheader {
  margin-top: 41px;
  margin-bottom: 35px;
}
