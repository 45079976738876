.text-and-images {
  color: var(--text-color);
  margin-top: 60px;
  margin-bottom: 60px;
  max-width: 912px;
  &__title {
    @extend .list__title;
  }
  &__images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
    }
    img {
      width: 100%;
    }
  }
}
