
:root {
  --blue-gradient: linear-gradient(90deg, #4047D5 0%, #3136AE 53.51%, #272A92 100%);
  --text-color: #343F65;
  --dark-blue: #282B93;
  --green: #21C98C;
  --red: #FF5A5A;
  --white: #fff;
}

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
