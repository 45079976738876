.card {
  background-color: white;
  border-radius: 5px;
  box-shadow: 3px 10px 15px rgba(0, 0, 0, 0.07);
  padding: 30px;
  color: var(--dark-blue);
  position: relative;
  @include media-breakpoint-down(md) {
    padding: 30px 18px;
  }
  &__text {
    color: var(--text-color);
    &--narrow {
      max-width: 1110px;
    }
    &--two-col {
      column-count: 2;
      column-gap: 30px;
      @include media-breakpoint-down(sm) {
        column-count: 1;
        font-size: 14px;
      }
      p {
        margin-top: 0;
        break-inside: avoid-column;
        line-height: 32px;
        @include media-breakpoint-down(sm) {
          line-height: 24px;
        }
      }
      a {
        color: var(--text-color);
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    color: var(--text-color);
    padding: 40px 30px 30px;
    border-bottom: 1px solid var(--text-color);
    margin-bottom: 17px;
    @include media-breakpoint-down(sm) {
      padding: 10px 18px 16px;
    }
    &__item {
      &__label {
        text-transform: uppercase;
        font-size: 15px;
        letter-spacing: 0.08em;
        @include media-breakpoint-down(sm) {
          font-size: 13px;
        }
      }
      h5 {
        margin-top: 8px;
        margin-bottom: 0;
        font-size: 30px;
        @include media-breakpoint-down(sm) {
          font-size: 22px;
          margin-top: 1px;
        }
        small {
          font-size: 16px;
          @include media-breakpoint-down(sm) {
            font-size: 13px;
          }
        }
      }
    }
  }
  &__content {
    padding: 0 30px 26px;
    @include media-breakpoint-down(sm) {
      padding: 0 18px 26px;
    }
    &__price {
      display: flex;
      flex-direction: column;
      color: var(--text-color);
      margin-top: 23px;
      @include media-breakpoint-down(sm) {
        margin-top: 10px;
      }
      &__label {
        font-size: 15px;
        text-transform: uppercase;
        line-height: 25px;
        font-weight: 700;
        @include media-breakpoint-down(sm) {
          font-size: 13px;
          margin-bottom: -5px;
        }
      }
      &__amount {
        font-family: 'Montserrat', sans-serif;
        font-size: 30px;
        line-height: 36px;
        font-weight: 700;
        small {
          font-size: 16px;
          font-weight: 700;
        }
      }
      &--green {
        color: var(--green);
      }
    }
  }
  &__button {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  &__column {
    width: calc(50% - 140px);
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    &__text {
      &__icon {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        i {
          margin-right: 10px;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
      &--shortened {
        @include media-breakpoint-down(sm) {
          &>*:not(:first-child) {
            display: none;
          }
          margin-bottom: 40px;
          cursor: pointer;
        }
        &:after {
          @include media-breakpoint-down(sm) {
            content: "Read more...";
            display: block;
          }
        }
      }
      &+.text-input {
        margin-top: 20px !important;
      }
    }
    h2 {
      @extend .list__title;
    }
    p {
      margin-top: 0;
      margin-bottom: 24px;
      line-height: 24px;
      font-size: 16px;
    }
    .text-input {
      margin-top: 63px;
    }
    &__form {
      &__row {
        margin-bottom: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn {
          font-size: 12px;
          padding: 2px !important;
          width: 158px !important;
          flex-grow: 0
        }
        &>* {
          flex-grow: 1;
          margin-top: 0 !important;
          max-width: calc(50% - 15px);
        }
        &--btn {
          .text-input {
            flex-basis: 342px;
            max-width: unset;
          }
          .btn {
            flex-basis: 158px;
            margin-left: 30px;
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }
      }
      .btn {
        margin-top: 30px;
      }
      .checkbox-label {
        margin-top: 50px;
        display: inline-block;
      }
    }
    &:first-child {
      &:after {
        content: "";
        height: 100%;
        width: 1px;
        background-color: var(--dark-blue);
        position: absolute;
        top: 0;
        left: 50%;
        @include media-breakpoint-down(lg) {
          content: none;
        }
      }
    }
    &--narrow {
      width: calc(50% - 210px);
      margin-right: 70px;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
    &--wide {
      width: calc(50% - 50px);
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      &:not(:first-child) {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
      .btn {
        margin-top: 30px;
        width: 206px;
        padding: 17px 0;
        max-width: 100%;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
      .card__column__form {
        max-width: 503px;
      }
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    &:nth-last-child(1), &:nth-last-child(2) {
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
    }
    @include media-breakpoint-down(md) {
      width: 50%;
      margin-bottom: 20px;
    }
    h5 {
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 800;
      font-size: 20px;
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
        font-size: 18px;

      }
      span {
        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }
    }
    &>span {
      color: var(--text-color);
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
  }
  &__title {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0.01em;
    line-height: 22px;
    white-space: nowrap;
    color: var(--text-color);
    @include media-breakpoint-down(md) {
      white-space: normal;
    }
  }
  &__list {
    display: flex;
    justify-content: space-around;
    list-style: none;
    counter-reset: numbers;
    flex-basis: 710px;
    @include media-breakpoint-down(md) {
      flex-basis: unset;
      flex-direction: column;
      padding-left: 0;
      position: relative;
      margin-top: 40px;
    }
    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: var(--dark-blue);
      top: 71px;
      position: absolute;
      left: 0;
      @include media-breakpoint-down(md) {
        width: 1px;
        height: calc(100% - 20px);
        top: 10px;
        left: 17px;
      }
    }
    &__item {
      counter-increment: numbers;
      display: flex;
      flex-direction: column;
      position: relative;
      padding-top: 50px;
      font-size: 13px;
      font-weight: 700;
      font-family: 'Montserrat', sans-serif;
      @include media-breakpoint-down(md) {
        padding-left: 42px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
      &:before {
        content: counter(numbers);
        width: 34px;
        height: 34px;
        border-radius: 100%;
        background-color: white;
        border: 1px solid var(--dark-blue);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        position: absolute;
        top: 5px;
        left: 0;
      }
      &:after {
        content: "";
        width: 30px;
        height: 30px;
        border: 1px solid var(--dark-blue);
        position: absolute;
        border-radius: 100%;
        z-index: 2;
        top: 7px;
        left: 2px;
      }
      &--complete {
        &:before {
          color: white;
          background-color: var(--green);
          border-color: transparent;
        }
        &:after {
          border-color: white;
        }
      }
      &--active {
        &:before {
          color: white;
          background-color: var(--dark-blue);
          border-color: transparent;
        }
        &:after {
          border-color: white;
        }
      }
    }
  }
  &--horizontal {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    .card__title {
      margin-bottom: 34px;
      color: var(--dark-blue);
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }
  &--list {
    @include media-breakpoint-down(md) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  &--two-columns {
    display: flex;
    justify-content: space-between;
    color: var(--text-color);
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }
  &--border-top {
    border-top: 5px solid var(--dark-blue);
  }
  &--no-pad {
    padding: 0;
  }
}

.cards {
  &--grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 37px;
    padding-top: 30px;
    margin-bottom: 60px;
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 0;
    }
  }
  &--table {
    margin-top: 30px;
    border-collapse: separate !important;
    border-spacing: 0 13px;
    width: 100%;
    th {
      color: white;
      text-align: left;
      font-size: 13px;
      line-height: 26px;
      letter-spacing: -0.02em;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      &:nth-child(2) {
        text-align: center;
      }
    }
    .project-card {
      background-color: transparent;
      border-bottom: 13px solid transparent;
      &__column {
        padding-top: 37px;
        &__button {
          padding-top: 33px;
          .btn {
            font-size: 12px;
            line-height: unset;
            padding: 5px 1px;
            margin-right: 30px;
          }
        }
        .status-bar {
          margin-top: 12px;
        }
        &--image {
          height: 92px;
          width: 151px;
          display: flex;
          padding-top: 0px;
          &__text {
            padding-top: 37px;
            a {
              white-space: nowrap;
              color: var(--text-color);
              font-weight: 700;
              margin-bottom: 4px;
              display: inline-block;
            }
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin-right: 22px;
          }
        }
        &--align-end {
          text-align: right;
          width: 150px;
          @include media-breakpoint-down(xl) {
            width: 100px;
          }
        }
      }
      td {
        background-color: white;
        overflow: hidden;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 0.02em;
        vertical-align: top;
        padding: 0;
        &>img {
          width: 151px;
          height: 92px;
          object-fit: cover;
        }
        &:first-child {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }
        &:last-child {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
    }
    &--white {
      width: 100%;
      .table__header {
        th {
          color: var(--text-color);
          &:after {
            background-color: var(--text-color);
          }
        }
      }
      .project-card {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        &__column {
          &--align-end {
            width: 75px;
          }
        }
      }
    }
  }
}

.project-card {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  &__image {
    padding-bottom: 48%;
    background-size: cover;
    position: relative;
    @include media-breakpoint-down(lg) {
      padding-bottom: 70%;
    }
    &__time-left {
      position: absolute;
      border-radius: 20px;
      text-transform: uppercase;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
      font-size: 13px;
      letter-spacing: 0.01em;
      font-weight: 700;
      color: white;
      top: 18px;
      right: 14px;
      padding: 5px 9px;
      z-index: 1;
      &:before, &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;

      }
      &:before {
        width: 100%;
        height: 100%;
        background-color: var(--green);
        z-index: -1;
      }
      &:after {
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        background-color: #A5A5B0;
        z-index: -2;
      }
    }
    &__status-bar {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  &__text {
    padding: 15px 20px 30px;
    color: var(--text-color);
    &__title {
      font-size: 20px;
      margin-top: 0;
      letter-spacing: 0.02em;
      line-height: 34px;
      margin-bottom: 10px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 5px;
      }
    }
    &__about {
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.02em;
      margin-bottom: 10px;
    }
    &__list {
      list-style: none;
      padding-left: 0;
      column-count: 2;
      column-gap: 250px;
      @include media-breakpoint-down(xxl) {
        column-gap: 150px;
      }
      @include media-breakpoint-down(xl) {
        column-gap: 50px;
      }
      @include media-breakpoint-down(lg) {
        column-count: 1;
      }
      li {
        font-size: 16px;
        line-height: 29px;
        display: flex;
        justify-content: space-between;
        strong {
          font-family: 'Montserrat', sans-serif;
        }
      }
    }
    &__links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .link {
        font-size: 16px;
        color: var(--dark-blue);
      }
    }
  }
}
