i {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  &.google-logo {
    background-image: url('/img/google.svg');
    width: 62px;
    height: 20px;
    margin-bottom: -2px;
  }
  &.bank-id-logo {
    background-image: url('/img/logo-bank-id.svg');
    width: 71px;
    height: 15px;
  }
  &.home-icon {
    background-image: url('/img/home.svg');
    width: 34px;
    height: 30px;
  }
  &.cubes-icon {
    background-image: url('/img/cubes.svg');
    width: 34px;
    height: 30px;
  }
  &.layers-icon {
    background-image: url('/img/layers.svg');
    width: 34px;
    height: 30px;
  }
  &.support-icon {
    background-image: url('/img/support.svg');
    width: 34px;
    height: 30px;
  }
  &.bell-icon {
    background-image: url('/img/bell.svg');
    width: 22px;
    height: 25px;
    &--notification {
      position: relative;
      &:after {
        content: "";
        width: 4px;
        height: 4px;
        background-color: var(--green);
        border-radius: 100%;
        position: absolute;
        top: 1px;
        right: -4px;
      }
    }
  }
  &.plus {
    position: relative;
    width: 12px;
    height: 12px;
    padding-right: 5px;
    &:before, &:after {
      content: "";
      height: 1px;
      width: 12px;
      background-color: var(--dark-blue);
      position: absolute;
      left: 0;
      top: 50%;
    }
    &::after {
      transform: rotate(90deg) translateY(-50%);
    }
    &:before {
      transform: translateY(-50%)
    }
  }
  &.question-blue {
    background-image: url('/img/help.svg');
    width: 15px;
    height: 15px;
    margin-left: 3px;
  }
  &.card-icon {
    background-image: url('/img/card.svg');
    width: 32px;
    height: 25px;
    opacity: 0.7;
    margin-right: 10px;
  }
  &.person-icon {
    background-image: url('/img/person.svg');
    width: 25px;
    height: 25px;
    margin-right: 10px;
    opacity: 0.7;
  }
  &.bank-icon {
    background-image: url('/img/bank.svg');
    width: 25px;
    height: 25px;
    margin-right: 10px;
    opacity: 0.7;
  }
  &.lock-icon {
    background-image: url('/img/lock.svg');
    width: 25px;
    height: 25px;
    margin-right: 10px;
    opacity: 0.7;
  }
  &.fingerprint-icon {
    background-image: url('/img/finger-print.svg');
    width: 25px;
    height: 26px;
    margin-right: 10px;
    opacity: 0.7;
  }
  &.grid-icon {
    background-image: url('/img/grid.svg');
    width: 25px;
    height: 25px;
  }
  &.table-icon {
    background-image: url('/img/table.svg');
    width: 29px;
    height: 26px;
  }
  &.check-shield {
    background-image: url('/img/check-shield.svg');
    width: 32px;
    height: 33px;
    flex-shrink: 0;
  }
  &.download-icon {
    background-image: url('/img/download-icon.svg');
    width: 14px;
    height: 16px;
  }
}
