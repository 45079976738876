.two-columns {
  display: flex;
  justify-content: space-between;
  color: var(--text-color);
  @include media-breakpoint-down(xl) {
    flex-direction: column;
    margin-bottom: 30px;
  }
  &__column {
    border-radius: 5px;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      order: 1
    }
    &__image {
      &--cover {
        height: 100%;
        object-fit: cover;
      }
    }
    &:first-child {
      width: calc(46% - 33px);
      @include media-breakpoint-down(xl) {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    &:last-child {
      width: calc(54% - 33px);
      @include media-breakpoint-down(xl) {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    img {
      width: 100%;
    }
    &>.btn {
      width: fit-content;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    &--overflow {
      overflow: visible;
    }
    &--mobile-first {
      @include media-breakpoint-down (sm) {
        order: 0;
        overflow: visible;
      }
    }
    &--image {
      @include media-breakpoint-down(sm) {
        height: 180px;
      }
    }
  }
  &--shadow {
    .two-columns__column {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    }
  }
}
